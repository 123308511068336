import React, { useEffect, useState } from 'react'
import { Link, navigate } from 'gatsby'
import edjsHTML from 'editorjs-html'
import moment from 'moment'
import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'
import SEO from '../../components/seo'
import GeneralLayout from '../../components/layouts/general'
import AppLayout from '../../components/layouts/appLayout'
import { HeadingContainer } from '../../components/common/typography'
import {
  BLACK_COLOR_RGB,
  PRIMARY_COLOR,
  PRIMARY_COLOR_RGB,
} from '../../components/common/color'
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader'
import { getBlogPostById } from '../../utils/requests'
import matter from 'gray-matter'
import { Remarkable } from 'remarkable'

const remarkable = new Remarkable()

deckDeckGoHighlightElement()

const Content = styled.div`
  padding-top: 2rem;
  max-width: 60%;
  margin: 0 auto;
  box-sizing: border-box;
`

const PostDate = styled.span`
  color: ${BLACK_COLOR_RGB(0.7)};
`

const BackToBlogLink = styled(Link)`
  position: relative;
  font-weight: bold;
  color: ${PRIMARY_COLOR};
  text-transform: uppercase;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: -0.5rem;
    width: 56px;
    height: 4px;
    background-color: ${PRIMARY_COLOR_RGB(0.8)};
  }

  &:hover {
    &::before {
      background-color: ${BLACK_COLOR_RGB(0.6)};
    }
  }
`

const BlogAwsView = ({ awsSlug }) => {
  const [post, setPost] = useState(null)
  useEffect(() => {
    const fetchBlogPostBySlug = async () => {
      try {
        const response = await getBlogPostById(awsSlug)

        if (!response.success) {
          navigate('/404')
          return
        }

        // validate response.data.Item.data if is JSON or MD
        let html
        const content = response.data.Item.data

        const rawHtmlParser = (block) => block.data.html

        if (typeof content === 'string') {
          // Markdown pages (legacy)
          const { content } = matter(response.data.Item.data)
          html = remarkable.render(content)
        } else {
          // EditorJS pages
          const edjsParser = edjsHTML({ raw: rawHtmlParser })
          html = edjsParser.parse(response.data.Item.data).join('')
        }

        setPost({
          ...response.data.Item,
          date: moment(response.data.Item.date).format('MMMM Do, YYYY'),
          html,
        })
      } catch (err) {
        navigate('/404')
      }
    }
    fetchBlogPostBySlug()
  }, [awsSlug])

  if (!post) {
    return null
  }

  const { date, title, html } = post

  return (
    <AppLayout>
      <SEO title={title} canonical={`blog/${awsSlug}`} />
      <Global
        styles={css`
          .blog-html {
            font-family: var(--primary-font);
            margin: 1rem 0;

            h1 {
              margin: 0.5rem 0;
            }

            h4 {
              margin: 0.25rem 0;
            }

            * {
              line-height: 1.5;
            }

            img {
              max-width: 800px;
              width: 100%;
              display: block;
              margin: 0 auto;
            }

            deckgo-highlight-code {
              display: block;
              margin: 0 auto;
              max-width: 800px;
            }
          }
        `}
      />
      <Content>
        <BackToBlogLink to="/blog">Back to Blog</BackToBlogLink>
        <HeadingContainer title={title} style={{ marginTop: '2rem' }} />
        <PostDate>
          <strong>Published At: </strong>
          {date}
        </PostDate>
        <div
          className="blog-html"
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
      </Content>
    </AppLayout>
  )
}

export default BlogAwsView
